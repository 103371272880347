import React from "react";
import base64 from "base-64";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { SupportLocalStorage } from "../common/detectLocalStorage";
import logger from "./logService";
import { deviceDetect, isMobileOnly } from "react-device-detect";
import { jwtToken, jwtAdminToken } from "../common/config.json";

const isSuportLocalStorage = SupportLocalStorage();
const tokenTemplate = jwtToken.tokenTemplate;
const tokenKey = jwtToken.tokenKey;
const tokenUser = jwtToken.tokenUser;
const tokenAdmin = jwtAdminToken.tokenAdmin;
const tokenKeyAdmin = jwtAdminToken.tokenKeyAdmin;
const tokenLanguageData = jwtToken.tokenLanguageData;
const tokenMenu = jwtToken.tokenMenu;
const tokenAllPermissions = jwtToken.tokenAllPermissions;
const tokenMapCnf = jwtToken.tokenMapCnf;

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
  }

  return Promise.reject(error);
});

function getAxios(url, data) {
  const mapCnf = window.localStorage.getItem("MapCnf")
    ? JSON.parse(window.localStorage.getItem("MapCnf"))
    : [];
  let token = "";
  if (
    !sessionStorage.getItem("AuthToken") ||
    (url.includes(process.env.REACT_APP_API_ADMIN_HOST) &&
      !localStorage.getItem(jwtAdminToken.tokenAdmin)) ||
    (mapCnf.REACT_APP_HERE_RME_API && mapCnf.REACT_APP_HERE_RME_API == url)
  ) {
    return axios.get(url, data, {});
  } else {
    if (url.includes(process.env.REACT_APP_API_ADMIN_HOST)) {
      token = localStorage.getItem(jwtAdminToken.tokenAdmin);
    } else {
      token = sessionStorage.getItem("AuthToken");
    }
    let config = {};
    if (data) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: data.params
      };
    } else {
      config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
    return axios.get(url, config);
  }
}

function postAxios(url, data) {
  let token = "";
  if (url.includes(process.env.REACT_APP_API_ADMIN_HOST)) {
    token = localStorage.getItem(jwtAdminToken.tokenAdmin);
  } else {
    token = sessionStorage.getItem("AuthToken");
  }
  if (
    sessionStorage.getItem("AuthToken") ||
    (url.includes(process.env.REACT_APP_API_ADMIN_HOST) &&
      !localStorage.getItem(jwtAdminToken.tokenAdmin))
  ) {
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } else {
    return axios.post(url, data, {});
  }
}

function putAxios(url, data) {
  let token = "";
  if (url.includes(process.env.REACT_APP_API_ADMIN_HOST)) {
    token = localStorage.getItem(jwtAdminToken.tokenAdmin);
  } else {
    token = sessionStorage.getItem("AuthToken");
  }
  if (
    sessionStorage.getItem("AuthToken") ||
    (url.includes(process.env.REACT_APP_API_ADMIN_HOST) &&
      !localStorage.getItem(jwtAdminToken.tokenAdmin))
  ) {
    return axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } else {
    return axios.put(url, data, {});
  }
}

function deleteAxios(url, data) {
  let config = {};
  let token = "";
  if (url.includes(process.env.REACT_APP_API_ADMIN_HOST)) {
    token = localStorage.getItem(jwtAdminToken.tokenAdmin);
  } else {
    token = sessionStorage.getItem("AuthToken");
  }
  if (
    sessionStorage.getItem("AuthToken") ||
    (url.includes(process.env.REACT_APP_API_ADMIN_HOST) &&
      !localStorage.getItem(jwtAdminToken.tokenAdmin))
  ) {
    config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: data.data
    };
  } else {
    config = {
      data: data.data
    };
  }

  return axios.delete(url, config);
}

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

function setHeaderToken(token) {
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
}

function unSetHeaderToken() {
  axios.defaults.headers.common = {};
}

function getDeviceUser() {
  const device = deviceDetect();
  let isMobile = "Desktop";
  if (isMobileOnly) {
    isMobile = "Mobile";
  }

  const deviceDetails = {
    deviceType: isMobile,
    browser: device.browserName,
    browserVersion: device.engineVersion,
    clientPlatform: device.osName + " " + device.osVersion
  };

  return deviceDetails;
}

function baseURL() {
  const domain = window.location.protocol + "//" + window.location.hostname;
  return domain;
}

function getHost() {
  const domain = window.location.hostname;
  return domain;
}

function setTokenBy(tokenName, TokenValue) {
  if (tokenName == "AuthToken") {
    sessionStorage.setItem(tokenName, TokenValue);
  } else {
    if (isSuportLocalStorage) {
      localStorage.setItem(tokenName, TokenValue);
    } else {
      Cookies.set(tokenName, TokenValue);
    }
  }
}
function getRawTokenBy(key) {
  if (key == "AuthToken") {
    return sessionStorage.getItem(key);
  } else {
    if (isSuportLocalStorage) {
      return localStorage.getItem(key);
    } else {
      return Cookies.get(key);
    }
  }
}

function getAllRawToken() {
  if (isSuportLocalStorage) {
    let values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
    while (i--) {
      values.push({ [keys[i]]: this.getRawTokenBy(keys[i]) });
    }

    return values;
  }
}

export function decodeJwt(stringValue) {
  return jwtDecode(stringValue);
}

function setPageTitle(brandName, Title) {
  document.title = brandName + " | " + Title;
}

function getAdminTokenKeyRaw() {
  try {
    const jwt = this.getRawTokenBy(tokenKeyAdmin);
    return jwt;
  } catch (ex) {
    return null;
  }
}
function getAdminDataRaw() {
  try {
    const jwt = this.getRawTokenBy(tokenAdmin);
    return jwt;
  } catch (ex) {
    return null;
  }
}

function getCurrentTokenKeyRaw() {
  try {
    const jwt = this.getRawTokenBy(tokenKey);
    return jwt;
  } catch (ex) {
    return null;
  }
}

function getCurrentUserDataRaw() {
  try {
    const jwt = this.getRawTokenBy(tokenUser);
    return jwt;
  } catch (ex) {
    return null;
  }
}
function getCurrentTokenKey() {
  try {
    const jwt = this.getRawTokenBy(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getCurrentUserData() {
  try {
    const jwt = this.getRawTokenBy(tokenUser);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getLanguageData() {
  try {
    const jwt = this.getRawTokenBy(tokenLanguageData);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getMenu() {
  try {
    const jwt = this.getRawTokenBy(tokenMenu);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getAllPermissions() {
  try {
    const jwt = this.getRawTokenBy(tokenAllPermissions);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getAdminData() {
  try {
    const jwt = this.getRawTokenBy(tokenAdmin);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getCurrentTemplateData() {
  try {
    const jwt = this.getRawTokenBy(tokenTemplate);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

function getMapConfig() {
  try {
    const token = this.getRawTokenBy(tokenMapCnf);
    return token;
  } catch (ex) {
    return null;
  }
}

export async function logout() {
  const api = baseAPIurl + "/auth/logout";
  const params = {};

  const result = await await axios.post(api, params);
  sessionStorage.removeItem(tokenKey);
  localStorage.clear();
}

function checkExpiredLocalStorage() {
  try {
    const token = this.getCurrentTokenKey();
    if (token) {
      if (new Date(token.Exp.date) > new Date()) {
        //Keep Login & login success
        return false;
      } else {
        //Logout function call here
        this.logout();
        sessionStorage.removeItem(tokenKey);
        localStorage.clear();
        return true;
      }
    } else {
      return false;
    }
  } catch (ex) {
    return true;
  }
}

function clearAdminToken() {
  try {
    if (isSuportLocalStorage) {
      Object.entries(jwtAdminToken).forEach(([key, value]) =>
        localStorage.removeItem(value)
      );
    }
  } catch (ex) {
    return null;
  }
}

function clearAllToken() {
  try {
    if (isSuportLocalStorage) {
      Object.entries(jwtToken).forEach(([key, value]) =>
        localStorage.removeItem(value)
      );
    }
  } catch (ex) {
    return null;
  }
}

function renderCSSImage(imageString) {
  let bgStyle = {
    backgroundImage: `url(${imageString})`
  };
  return bgStyle;
}

function renderDomImage(imageString) {
  let img = (
    <React.Fragment>
      <img src={imageString} alt="" />
    </React.Fragment>
  );
  return img;
}

function checkLoginUser() {
  const Token = this.getCurrentTokenKey();
  if (Token) {
    const user = this.getCurrentUserData();
    if (user) {
      if (Token.RandomStr && Token.UID === user.User.Id) return true;
    }
    return false;
  }
  return false;
}
const MapCnf = window.localStorage.getItem("MapCnf")
  ? JSON.parse(window.localStorage.getItem("MapCnf"))
  : [];

export const baseAPIServerUrl = process.env.REACT_APP_API_SERVER_URL;
export const baseReportAPI = process.env.REACT_APP_REPORT_API;
export const sessionTimeOut = process.env.REACT_APP_SESSION_INACTIVITY_TIMEOUT;
export const baseAPIurl = process.env.REACT_APP_API_HOST;
export const adminApiUrl = process.env.REACT_APP_API_ADMIN_HOST;
export const APP_ID = MapCnf.length === 0 ? "" : MapCnf.REACT_APP_ID_HERE;
export const CODE_HERE = MapCnf.length === 0 ? "" : MapCnf.REACT_APP_CODE_HERE;
export const APP_ID_WEATHER =
  MapCnf.length === 0 ? "" : MapCnf.REACT_APP_ID_HERE_WEATHER;
export const CODE_HERE_WEATHER =
  MapCnf.length === 0 ? "" : MapCnf.REACT_APP_CODE_HERE_WEATHER;
export const REACT_APP_WSURL = `wss:\\${window.location.hostname}${process.env.REACT_APP_WSURL}`;

export const WEATHER_API = `${process.env.REACT_APP_PROXYURL}/${
  MapCnf.length === 0
    ? "https://weather.api.here.com/weather/1.0/report.json"
    : MapCnf.REACT_APP_HERE_WEATHER_API
}`;
export const RME_API = MapCnf.length === 0 ? "" : MapCnf.REACT_APP_HERE_RME_API;

export const GAPIKEY = MapCnf.length === 0 ? "" : MapCnf.REACT_APP_GAPIKEY;

export const INCIDENT_API = `${process.env.REACT_APP_PROXYURL}/${
  MapCnf.length === 0
    ? "https://traffic.api.here.com/traffic/6.3/incidents.json"
    : MapCnf.REACT_APP_INCIDENT_API
}`;

// export const PROXYURL = () => {
//   // if (window.location.protocol !== "https:")
//   //   return process.env.REACT_APP_PROXYURL + "/";
//   // return false;
//   return process.env.REACT_APP_PROXYURL + "/";

//   let weatherURL =  process.env.REACT_APP_PROXYURL + "/";
//   if (process.env.REACT_APP_ENV === 'production'){
//     weatherURL =
//   }
//   return weatherURL;

// };

function randomNum() {
  return Math.floor(Math.random() * 256);
}

export const userFirstPage = FirstPage => {
  let firstLoad = "/";
  switch (FirstPage) {
    case "D":
      firstLoad = "fleet";
      break;
    case "M":
      firstLoad = "fleet";
      break;
    case "L":
      firstLoad = "fleet";
      break;
    case "I":
      firstLoad = "fleet";
      break;
    default:
      firstLoad = "fleet";
  }
  return firstLoad;
};

export const RandomColor = () => {
  let letters = "012345".split("");
  let color = "#";
  color += letters[Math.round(Math.random() * 5)];
  letters = "0123456789ABCDEF".split("");
  for (var i = 0; i < 5; i++) {
    color += letters[Math.round(Math.random() * 15)];
  }

  return color;
};

export const RandomDashboardColor = () => {
  var red = randomNum();
  var green = randomNum();
  var blue = randomNum();
  return [red, green, blue];
};

export const createGPXFiles = coordinates => {
  const gpxHeaderDoc = `<gpx version="1.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/0" xsi:schemaLocation="http://www.topografix.com/GPX/1/0 http://www.topografix.com/GPX/1/0/gpx.xsd">`;
  let gpxContentDoc = gpxHeaderDoc + "<trk><trkseg>";
  coordinates.forEach((item, index) => {
    if (index > 0 && index < coordinates.length - 2) {
      // not include start & end points
      let Lat = parseFloat(item.lattitude);
      let Long = parseFloat(item.longitude);
      gpxContentDoc += `<trkpt lat="${Lat}" lon="${Long}">` + ` </trkpt>`;
    }
  });
  gpxContentDoc += "</trkseg></trk></gpx>";
  return base64.encode(gpxContentDoc);
};

const jsonwebtoken = require("jsonwebtoken");
export const generateToken = fields => {
  return JSON.stringify(fields);
  //removed encoding to remove JWT secret code from env which is exposed to public
  // return jsonwebtoken.sign(fields, process.env.REACT_APP_JWT_SCT, {
  //   expiresIn: 60 * 60 * 1 // expires in 1 hours
  // });
};

export const getMatchPointsRME = async gpxContentDoc => {
  const MapCnf = window.localStorage.getItem("MapCnf")
    ? JSON.parse(window.localStorage.getItem("MapCnf"))
    : [];
  unSetHeaderToken();
  const { data: result } = await axios.get(
    MapCnf.length === 0 ? "" : MapCnf.REACT_APP_HERE_RME_API,
    {
      params: {
        app_id: MapCnf.length === 0 ? "" : MapCnf.REACT_APP_ID_HERE,
        app_code: MapCnf.length === 0 ? "" : MapCnf.REACT_APP_CODE_HERE,
        routemode: "truck",
        attributes: "SPEED_LIMITS_FCn(FROM_REF_SPEED_LIMIT,TO_REF_SPEED_LIMIT)",
        file: gpxContentDoc
      }
    }
  );
  setHeaderToken(sessionStorage.getItem("AuthToken"));

  const matchAllPoints = [];

  if (result.RouteLinks) {
    const RouteLinks = result.RouteLinks;
    // console.log("RouteLinks", RouteLinks);
    // const TracePoints = result.TracePoints;
    // const WarningsPoints = result.Warnings;

    // const allInfos = [];
    if (RouteLinks && RouteLinks.length > 0) {
      // let itemInfo = {};

      //let speed_limit_fcn = 0; //Road Speed Limit
      RouteLinks.map((item, index) => {
        // const shapeArray = [];
        let shapeString = item.shape.split(" "); // make to be array
        let latShape = "";
        let longShape = "";
        /**
         * Road Speed Limit
         */
        /************************************ */
        // if (item.attributes && item.attributes.SPEED_LIMITS_FCN[0]) {
        //   let toRefSpeedLimit =
        //     item.attributes.SPEED_LIMITS_FCN[0].TO_REF_SPEED_LIMIT;
        //   speed_limit_fcn = toRefSpeedLimit;
        // }

        /**
         * Match Point & Data Info from Origial Points
         */
        shapeString.map((item2, index2) => {
          if (parseFloat(index2) % 2 === 0) {
            latShape = parseFloat(item2);
          } else {
            longShape = parseFloat(item2);
            matchAllPoints.push([latShape, longShape]);
          }
        }); //end RouteLinks
      });
      // const RMWWithStartEnd = [...startPoint, ...matchAllPoints, ...endPoint];
      // dataPoints.push(...matchAllPoints);
    } else {
      console.log("RME, has an error");
    }
  }
  // console.log(matchAllPoints);
  return matchAllPoints;
};

export default {
  // get: (url, data) => getAxios(url, data),
  // post: (url, data) => postAxios(url, data),
  // put: (url, data) => putAxios(url, data),
  // delete: (url, data) => deleteAxios(url, data),
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  deviceUser: getDeviceUser(),
  baseURL,
  getHost,
  setTokenBy,
  getAllRawToken,
  getRawTokenBy,
  decodeJwt,
  setPageTitle,
  getCurrentTokenKeyRaw,
  getCurrentTokenKey,
  getCurrentUserDataRaw,
  getCurrentUserData,
  getLanguageData,
  getMenu,
  getAllPermissions,
  getAdminData,
  getCurrentTemplateData,
  checkExpiredLocalStorage,
  clearAllToken,
  clearAdminToken,
  renderCSSImage,
  renderDomImage,
  getAdminTokenKeyRaw,
  getAdminDataRaw,
  checkLoginUser,
  getMapConfig,
  setHeaderToken,
  unSetHeaderToken
};

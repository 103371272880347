import http, { baseAPIurl } from "./httpService";
const moment = require("moment");

const domainName = http.getHost();
const apiEndpoint = baseAPIurl + "/template/getCustomerPortals";
const apiEndpointBasic = baseAPIurl + "/template/getBasicCustomerPortals";

export async function getCustomerPortals() {
  try {
    const authToken = http.getCurrentTokenKeyRaw();
    const params = {
      domain: domainName,
      format: "ARRAY"
    };
    if (authToken === null) {
      const { data: jwt } = await http.post(apiEndpointBasic, params);
      return jwt;
    } else {
      const { data: jwt } = await http.post(apiEndpoint, params);
      return jwt;
    }
  } catch (ex) {
    return null;
  }
}

export async function getPortalLanguageData() {
  try {
    const api = baseAPIurl + "/template/getPortalLanguageData";
    const params = {
      format: "ARRAY"
    };
    const { data: jwt } = await http.post(api, params);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export async function getUserPermissions() {
  try {
    const currentUser = http.getCurrentUserData();
    const userId = currentUser.User.Id;

    const api = baseAPIurl + `/user/permissions?userId=${userId}`;
    const { data: jwt } = await http.get(api);
    return jwt;
  } catch (ex) {
    return null;
  }
}

export function formatDate(date, inputFormat, outputFormat) {
  //specify the date string and the format it's initially in
  var mydate = moment(date, inputFormat);

  //format that date into a different format
  moment(mydate).format(outputFormat);
  return mydate;
}

export default {
  getCustomerPortals,
  getPortalLanguageData,
  getUserPermissions,
  formatDate
};
